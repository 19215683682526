<template>
    <v-data-table 
        :loading="loading"
        :items="items"
        :options.sync="options"
        :server-items-length="meta.total_items"
        :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
        }"
        v-bind="$attrs"
    >
        <template
            v-for="(_, s) in $scopedSlots"
            #[s]="props"
        > 
            <slot
                :name="s"
                v-bind="props"
            /> 
        </template>
    </v-data-table>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        payload: {
            type: Object,
            default: () => ({})
        },
        filters: {
            type: Object,
            default: () => ({})
        },
        request: {
            type: [Function, String],
            required: true
        },
        itemsMapKey: {
            type: String,
            default: null
        },
        metaMapKey: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            meta: {},
            items: [],
            options: {
                itemsPerPage: 10,
            },
        }
    },
    methods: {
        async getResponse() {
            if (typeof this.request === 'function') {
                return this.request({
                    page: this.options.page,
                    limit: this.options.itemsPerPage,
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                    filters: this.filters,
                })
            }

            const method = this.$api[this.request] 

            if (!method) {
                console.error('Method not found')

                return {
                    meta: {},
                    data: [],
                }
            }

            const response = await method({
                page: this.options.page,
                limit: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                filters: this.filters,
            })

            if (response.error) {
                return {
                    error: true,
                    meta: {},
                    data: [],
                }
            }

            let data = response.message
            let meta = response.meta

            if (this.itemsMapKey) {
                data = get(response, this.itemsMapKey)
            }

            if (this.metaMapKey) {
                meta = get(response, this.metaMapKey)
            }

            return {
                meta,
                data,
            }
        },
        async load(){
            this.loading = true 

            const { data, meta } = await this.getResponse() 

            if (!Array.isArray(data)) {
                this.loading = false
                console.error('Data is not an array', data)
                return
            }

            this.items = data
            this.meta = meta

            setTimeout(() => {
                this.loading = false
            }, 1000)

        },
    },
    watch: {
        options: {
            handler: 'load',
            deep: true
        },
        filters: {
            deep: true,
            handler(){
                this.options = {
                    ...this.options,
                    page: 1
                }
            }
        }
    }
}
</script>
