<template>
    <v-avatar
        v-bind="$attrs"
        :size="size"
    >
        <v-img
            v-if="user.profile_picture"
            :src="user.profile_picture"
        />
        <div
            v-else
            class="w-full h-full primary d-flex justify-center"
        >
            <v-icon
                color="white"
                :size="iconSize"
            >
                mdi-account
            </v-icon>
        </div>
    </v-avatar>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
        size: {
            type: [Number, String],
            default: 48
        }
    } ,
    computed: {
        user: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        iconSize(){
            const size = Number(this.size);

            return size * 0.6;
        }
    }
}
</script>
