
<template>
    <v-card>
        <v-card-text>
            <v-form
                ref="form"
                @submit.prevent="submit"
            >
                <v-row>
                    <v-col cols="12"> 
                        <v-text-field
                            v-model="payload.password"
                            :label="$t('password')"
                            :type="showPassword ? 'text' : 'password'"
                            :rules="[
                                $rules.required(),
                                $rules.minLength(8, $t('passwordRequirements')),
                                $rules.regex(/(?=.*[!@#$%^&*_-])/, $t('passwordRequirements')),
                                $rules.regex(/[0-9]/, $t('passwordRequirements')),
                            ]"
                            outlined
                            hide-details="auto"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !payload.showPassword"
                        />    
                    </v-col>
                        
                    <v-col cols="12"> 
                        <v-text-field
                            v-model="payload.confirmPassword"
                            :label="$t('confirmPassword')"
                            outlined
                            hide-details="auto"
                            :rules="[$rules.required(), $rules.sameAs(payload.password, $t('passwordsDoNotMatch'))]"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !payload.showPassword"
                        />    
                    </v-col>
                        
                    <v-col
                        cols="12"
                        class="d-flex justify-end"
                    >
                        <v-btn
                            color="primary"
                            type="submit"
                        >
                            {{ $t('changePassword') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>
  
<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            saving: false,
            showPassword: false,
            payload: {
                password: '',
                confirmPassword: ''
            }
        };
    },
    computed: {
        user: {
            get(){
                return this.value;
            },
            set(value){
                this.$emit('input', value);
            }
        }
    },
    methods: {
        async submit(){
            if (!this.$refs.form?.validate()) {
                return;
            }

            const {  password } = this.payload;

            const response = await this.$api.updateUser({
                _id: this.user._id,
                password: password,
            })

            if (response.error) {
                return;
            }

            this.$toast('success', this.$t('entityUpdated', [this.$t('password')]));

            this.payload.password = '';
            this.payload.confirmPassword = '';

            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });

        }
    },
};
</script>
  
