<template>
    <v-tooltip
        left
        v-if="tooltip"
    >
        <template #activator="props">
            <v-btn
                v-bind="{
                    ...props.attrs,
                    ...$attrs,
                }"
                v-on="{
                    ...props.on,
                    ...$listeners,
                }"
            >
                <slot />
            </v-btn>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-btn
        v-else
        v-bind="$attrs"
        v-on="$listeners" 
    >
        <slot />
    </v-btn>
</template>

<script>
export default {
    props: {
        tooltip: {
            type: String,
            default: null,
        },
    }
}
</script>
