<template>
    <v-dialog v-model="dialog" max-width="500" scrollable>
        <v-card v-if="ticket">
            <v-card-title>
                <div>{{ $t('ticket') }} {{ `#${$number.zeroFill(ticket.number)}` }}</div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="ticket.customer_product">
                        <v-card outlined>
                            <v-card-title>
                                <div>{{ $t('equipment') }}</div>

                                <v-btn color="primary" class="ml-auto" @click="viewEquipment">
                                    {{ $t('view') }}
                                </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field :value="ticket.customer_product?.number" :label="$t('number')" hide-details="auto" outlined disabled />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field :value="ticket.customer_product?.product?.name" :label="$t('name')" hide-details="auto" outlined disabled />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select v-model="ticket.status" :label="$t('status')" :items="$store.state.ticket.status" item-text="text" item-value="value" hide-details="auto" outlined :disabled="isCustomer">
                                            <template #selection="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.status" />
                                            </template>

                                            <template #item="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.status" />
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-select v-model="ticket.type" :label="$t('type')" :items="$store.state.ticket.types" item-text="text" item-value="value" hide-details="auto" outlined :disabled="isCustomer">
                                            <template #selection="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.types" />
                                            </template>

                                            <template #item="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.types" />
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-select v-model="ticket.nature" :label="$t('nature')" :items="$store.state.ticket.natures" item-text="text" item-value="value" hide-details="auto" outlined :disabled="isCustomer">
                                            <template #selection="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.natures" />
                                            </template>

                                            <template #item="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.natures" />
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="ticket.assign_to" :label="$t('responsible')" :items="assemblers" item-text="name" item-value="_id" hide-details="auto" outlined :disabled="isCustomer">
                                            <template #selection="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name" />
                                                    <v-list-item-subtitle v-text="item.email" class="grey--text" />
                                                </v-list-item-content>
                                            </template>

                                            <template #item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name" />
                                                    <v-list-item-subtitle v-text="item.email" />
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>



                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-title>
                                <div>{{ $t('openData') }}</div>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="ticket.title" :label="$t('title')" hide-details="auto" outlined />
                                    </v-col>

                                    <v-col cols="12">
                                        <v-textarea v-model="ticket.description" :label="$t('description')" hide-details="auto" outlined />
                                    </v-col>
                                </v-row>
                            </v-card-text>


                            <v-card-subtitle class="d-flex">
                                <div>
                                    {{ $t('images') }}
                                </div>

                                <v-btn @click="addImage" class="ml-auto" color="primary">
                                    {{ $t('add') }}
                                </v-btn>
                            </v-card-subtitle>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-if="!ticket.images.length">
                                        <v-card outlined>
                                            <v-card-text class="text-center">
                                                {{ $t('noEntity', [$t('images')]) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col v-for="(image, index) in ticket.images" :key="index" cols="4">
                                        <v-card outlined>
                                            <app-img :src="image" width="100%" height="80" contain />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" v-if="!isCustomer">
                        <v-card outlined>
                            <v-card-title>
                                <div>Status da Fábrica</div>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select v-model="ticket.factory_status" label="Status da Fábrica" :items="$store.state.ticket.factory_status" item-text="text" item-value="value" hide-details="auto" outlined>
                                            <template #selection="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.factory_status" />
                                            </template>

                                            <template #item="{ item }">
                                                <app-chip-status :value="item.value" :items="$store.state.ticket.factory_status" />
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-textarea v-model="ticket.factory_comments" label="Comentários" hide-details="auto" outlined />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-title>
                                <div>{{ $t('closeData') }}</div>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea v-model="ticket.close_description" :label="$t('closeDescription')" hide-details="auto" outlined :readonly="isCustomer" />
                                    </v-col>
                                </v-row>
                            </v-card-text>


                            <v-card-subtitle class="d-flex">
                                <div>
                                    {{ $t('images') }}
                                </div>

                                <v-btn v-if="!isCustomer" @click="addCloseImage" class="ml-auto" color="primary">
                                    {{ $t('add') }}
                                </v-btn>
                            </v-card-subtitle>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-if="!ticket.close_images.length">
                                        <v-card outlined>
                                            <v-card-text class="text-center">
                                                {{ $t('noEntity', [$t('images')]) }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col v-for="(image, index) in ticket.close_images" :key="index" cols="4">
                                        <v-card outlined>
                                            <app-img :src="image" width="100%" height="80" contain />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="justify-end">
                <v-btn v-if="original.status != 'done' && !isCustomer" color="error" :loading="saving" @click="close">
                    {{ $t('saveAndFinalize') }}
                </v-btn>

                <v-btn color="primary" :loading="saving" @click="save">
                    {{ $t('save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        ticketId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            saving: false,
            original: null,
            ticket: null,
            assemblers: [],
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        isCustomer() {
            return this.$store.getters.isCustomer
        },
    },
    methods: {
        async setAssemblers() {
            const response = await this.$api.getAssemblers()

            if (response.error) {
                return
            }

            this.assemblers = response.message
        },
        async load() {
            const response = await this.$api.getTicket(this.ticketId)

            if (response.error) {
                this.$router.go(-1)
                return
            }

            this.ticket = response.message
            this.original = this.$utils.copy(this.ticket)
        },
        async save() {
            this.saving = true

            const close_images = []
            const images = []

            for await (const i of this.ticket.images) {
                if (!i.file) {
                    images.push(i)
                    continue
                }

                const upload = await this.$api.uploadImage(i.file)

                if (upload.error) {
                    this.saving = false
                    return
                }

                images.push({
                    src: upload.message,
                })
            }

            for await (const i of this.ticket.close_images) {
                if (!i.file) {
                    close_images.push(i)
                    continue
                }

                const upload = await this.$api.uploadImage(i.file)

                if (upload.error) {
                    this.saving = false
                    return
                }

                close_images.push({
                    src: upload.message,
                })
            }

            const response = await this.$api.updateTicket({
                ...this.ticket,
                images,
                close_images,
            })

            if (response.error) {
                this.saving = false

                return response
            }

            this.$toast('success', this.$t('entityUpdated', [this.$t('ticket')]))

            setTimeout(() => {
                this.saving = false
                this.dialog = false

                this.$emit('updated', response.message)

                this.load()
            }, 1000)

            return response
        },
        async close() {
            const oldStatus = this.ticket.status

            this.ticket.status = 'done'

            const response = await this.save()

            if (response.error) {
                this.ticket.status = oldStatus
                return
            }
        },
        async addImage() {
            const file = await this.$utils.pickFile({
                accept: 'image/*'
            })

            if (!file) return

            this.ticket.images.push({
                src: URL.createObjectURL(file),
                file,
            })
        },
        async addCloseImage() {
            const file = await this.$utils.pickFile({
                accept: 'image/*'
            })

            if (!file) return

            this.ticket.close_images.push({
                src: URL.createObjectURL(file),
                file,
            })
        },
        viewEquipment() {
            if (this.isCustomer) {
                return this.$router.push({
                    name: 'customer.dashboard.product-single',
                    params: {
                        customerId: this.ticket.customer?._id,
                        customerProductId: this.ticket.customer_product._id
                    }
                })
            }

            return this.$router.push({
                name: 'CustomerProductSingle',
                params: {
                    customerId: this.ticket.customer?._id,
                    customerProductId: this.ticket.customer_product._id
                }
            })
        }
    },
    mounted() {
        this.setAssemblers()
    },
    watch: {
        ticketId: {
            immediate: true,
            handler: 'load'
        }
    },
}
</script>