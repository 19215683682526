
<template>
    <v-card>
        <v-card-title>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    class="d-flex space-x-2 align-center"
                >
                    <v-text-field
                        :value="filters.search"
                        label="Buscar"
                        dense
                        hide-details
                        outlined
                        @change="filters.search = $event"
                    />
                    <v-btn
                        color="primary"
                        height="40"
                        @click="() => $refs.list?.load()"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>

                    <v-btn
                        color="primary"
                        height="40"
                        @click="drawer = true"
                    >
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </v-col>

                <v-col
                    class="d-flex justify-end space-x-2"
                >
                    <v-btn
                        color="primary"
                        height="40"
                        :to="`/customers/${academy._id}/products-qrcodes`"
                    >
                        <v-icon left>
                            mdi-qrcode
                        </v-icon>

                        Ver códigos QR
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>


        <academy-product-list-table
            ref="list"
            v-model="academy"
            :filters="filters"
        />

        <academy-product-list-drawer
            v-model="filters"
            :drawer.sync="drawer" 
        />
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            drawer: false,
            filters: {},
        };
    },
    computed: {
        academy: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    }
}
</script>
