<template>
    <v-card>
        <v-card-title>
            Catalog machine
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        :value="proposal.catalog_machine_id"
                        label="ID"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        :value="proposal.catalog_machine_source"
                        :label="$t('source')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        :value="proposal.catalog_machine_origin"
                        :label="$t('origin')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        :value="proposal.catalog_machine_link"
                        :label="$t('link')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        :value="$currency.main(proposal.catalog_machine_price)"
                        :label="$t('price')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        saving: false,
    }),
    computed: {
        proposal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
}
</script>
