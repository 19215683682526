<template>
    <v-data-table
        :dense="dense"
        :loading="loading"
        :headers="headers"
        :items="itemsModel"
        :item-class="() => 'cursor-pointer'"
        :server-items-length="metaModel.total_items"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
        @click:row="onClickRow"
    >
        <template #[`item.current_version.status`]="{ item }">
            <app-chip-status
                small
                :value="item.current_version.status"
                :items="$store.state.versions.versionStatus"
            />
        </template>

        <template #[`item.current_version.date`]="{ item }">
            {{ $date.defaultFormat(item.current_version.date) }}
        </template>

        <template #[`item.current_version.nationalized_cost`]="{ item }">
            {{ $currency.main(item.current_version.nationalized_cost) }}
        </template>

        <template #[`item.current_version.fob_cost`]="{ item }">
            {{ $currency.usd(item.current_version.fob_cost) }}
        </template>

        <template #[`item.current_version.final_price`]="{ item }">
            {{ $currency.main(item.current_version.final_price) }}
        </template>

        <template #[`item.current_version.final_discount_percentage`]="{ item }">
            {{ item.current_version.final_discount_percentage || 0 }}%
        </template>

        <template #[`item.actions`]="props">
            <slot
                name="actions"
                v-bind="props"
            />
        </template>

        <template #footer>
            <v-divider />
            <div class="py-5">
                <v-pagination
                    :value="page"
                    :length="metaModel.total_pages"
                    total-visible="7"
                    @input="setPage"
                />
            </div>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        meta: {
            type: Object,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        showSeller: {
            type: Boolean,
            default: false
        },
        showCustomer: {
            type: Boolean,
            default: false
        },
        showFob: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            default: () => ({})
        },
        initialRequestFilter: {
            type: Object,
            default: () => ({})
        },
        onClickRow: {
            type: Function,
            default: () => {}
        }
    },
    data: () => ({
        loading: false,
        innerMeta: {},
        innerItems: [],
        options: {},
        page: 1,
        sortBy: null,
        sortDesc: null,
    }),
    computed: {
        metaModel: {
            get() {
                if (this.meta) {
                    return this.meta
                }

                return this.innerMeta
            },
            set(value) {
                if (this.meta) {
                    return this.$emit('update:meta', value)
                }

                this.innerMeta = value
            }
        },
        itemsModel: {
            get() {
                if (this.items) {
                    return this.items
                }

                return this.innerItems
            },
            set(value) {
                if (this.items) {
                    return this.$emit('update:items', value)
                }

                this.innerItems = value
            }
        },
        headers() {
            const headers = [
                { text: this.$t('name'), value: 'name', width: 200 },
                {
                    text: this.$t('status'),
                    value: 'current_version.status',
                    width: 100
                },
                {
                    text: this.$t('value'),
                    value: 'current_version.final_price',
                    width: 90
                },
                {
                    text: this.$tc('discount'),
                    value: 'current_version.final_discount_percentage',
                    width: 120
                },
                { text: this.$t('date'), value: 'current_version.date', width: 155 },
                { text: '', value: 'actions', width: 120, sortable: false },
            ]

            if (this.showCustomer) {
                headers.splice(2, 0, { text: this.$tc('client'), value: 'customer.name', width: 160 })
            }

            if (this.showSeller) {
                headers.splice(1, 0, { text: this.$t('seller'), value: 'user.name', width: 160 })
            }

            if (this.showFob) {
                headers.splice(
                    3,
                    0,
                    { text: 'FOB', value: 'current_version.fob_cost', width: 100 },
                    { text: this.$t('nationalizedPrice'), value: 'current_version.nationalized_cost', width: 180 },
                )
            }

            return headers
        },
    },
    methods: {
        setPage(value){
            this.page = value
            this.load()
        },
        async load(){
            this.loading = true

            const { error, meta, message } = await this.$api.getV2Proposals({
                ...this.filters,
                page: this.page,
                orderBy: this.sortBy,
                orderDesc: this.sortDesc,
                limit: 20
            })

            if (error) {
                this.loading = false
                return
            }

            this.metaModel = meta
            this.itemsModel = message

            setTimeout(() => {
                this.loading = false
            }, 500)
        },
    },
    watch: {
        sortBy: 'load',
        sortDesc: 'load',
        filters: {
            deep: true,
            handler(){
                this.page = 1

                this.load()
            }
        }
    },
    created() {
        this.load()
    }
}
</script>
