<template>
    <v-card>
        <v-card-text>
            <v-form @submit.prevent="save">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col
                                cols="12"
                                class="d-flex justify-center"
                            >
                                <user-avatar
                                    v-model="payload"
                                    size="160"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="d-flex flex-column align-center justify-center "
                            >
                                <div class="d-flex mb-4">
                                    <v-btn
                                        color="primary"
                                        class="mb-2 mb-sm-0 mr-sm-2"
                                        @click="setImage"
                                    >
                                        Alterar
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        v-if="payload.profile_picture"
                                        @click="removeImage"
                                    >
                                        Remover
                                    </v-btn>
                                </div>

                                <div class="w-full text-center ">
                                    {{ $t('allowedFileFormatAndSize', ['JPG, PNG', '2MB']) }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                        <v-text-field
                            v-model="payload.name"
                            :label="$t('name')"
                            required
                            outlined
                            hide-details="auto"
                        />    
                    </v-col>

                    <v-col
                        cols="12"
                    > 
                        <v-text-field
                            v-model="payload.email"
                            :label="$t('email')"
                            required
                            outlined
                            hide-details="auto"
                        />    
                    </v-col>

                    <v-col
                        cols="12"
                        class="d-flex"
                    >
                        <v-spacer />
                        <v-btn
                            class="mr-2"
                            text
                            @click="load"
                            :disabled="saving"
                        >
                            Redefinir
                        </v-btn>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="saving"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row> 
            </v-form>
        </v-card-text>
    </v-card>
</template>
  
<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            saving: false,
            payload: {
                name: '',
                email: '',
                profile_picture: ''
            },
            image: {
                file: null,
                preview: null,
            },
            updatePasswordForm: {
                showPassword: false,
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        };
    },
    computed: {
        model: {
            get(){
                return this.value;
            },
            set(value){
                this.$emit('input', value);
            }
        },
        userImage(){
            return this.image.preview || this.payload.profile_picture;
        },
    },
    mounted(){
        this.load()

        this.$store.commit('setBreadcrumbs', [
            { label: this.$t('profile') },
        ])
    },
    methods: {
        async load(){
            this.payload = this.$utils.copy(this.model);
            
            this.image.preview = null;
            this.image.file = null;
        },
        removeImage(){
            this.image.file = null
            this.payload.profile_picture = null
        },
        async setImage(){
            const file = await this.$utils.pickFile()

            const preview = URL.createObjectURL(file)

            this.image.file = file
            this.payload.profile_picture = preview
        },
        async save(){
            this.saving = true;

            if (this.image.file) {
                const response = await this.$api.uploadImage(this.image.file);

                if (response.error) {
                    return
                }

                this.payload.profile_picture = response.message;
            }

            const response = await this.$api.updateUser(this.payload);

            if (response.error) {
                return;
            }

            this.model.name = this.payload.name;
            this.model.email = this.payload.email;
            this.model.profile_picture = this.payload.profile_picture;

            setTimeout(() => {
                this.saving = false;
                
                this.$toast('success', this.$t('entityUpdated', [this.$t('profile')]));
            }, 800);

        },
    },
};
</script>
  
