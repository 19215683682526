<template>
    <v-badge
        color="red"
        :content="filtersLength"
        :value="filtersLength"
        overlap
    >
        <v-btn
            color="primary"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <v-icon>mdi-filter</v-icon>
        </v-btn>
    </v-badge>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        filters: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        filtersLength() {
            return Object.values(this.filters).reduce((acc, cur) => {
                if (Array.isArray(cur)) {
                    return cur.length ? acc + 1 : acc
                }

                if (cur !== '' && cur !== null && cur !== undefined) {
                    return acc + 1
                }

                return acc
            }, 0)
        }
    }
}

</script>
