<template>
    <v-card>
        <v-card-title>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    class="d-flex space-x-2 align-center"
                >
                    <v-text-field
                        :value="filters.search"
                        label="Buscar"
                        single-line
                        dense
                        hide-details
                        outlined
                        @change="filters.search = $event"
                    />
                    <v-btn
                        color="primary"
                        height="40"
                        @click="load"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>


                    <v-badge
                        color="red"
                        :content="filtersLength"
                        :value="filtersLength"
                        overlap
                    >
                        <v-btn
                            color="primary"
                            height="40"
                            @click="drawer = !drawer"
                        >
                            <v-icon>mdi-filter</v-icon>
                        </v-btn>
                    </v-badge>
                </v-col>

                <v-col
                    class="d-flex justify-end space-x-2"
                >
                    <v-btn
                        color="primary"
                        @click="dialog = true"
                    >
                        {{ $t('addNew') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
       
        <v-divider />

        <academy-list-table
            ref="table"
            :filters="filters"
        />

        <academy-list-drawer
            v-model="filters"
            :drawer.sync="drawer"
        />

        <academy-list-dialog
            v-model="dialog"
        />
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            drawer: false,
            dialog: false,
            filters: {
                search: ''
            }
        }
    },
    computed: {
        filtersLength() {
            return Object.values(this.filters).reduce((acc, cur) => {
                if (Array.isArray(cur) && cur.length) {
                    return acc + 1
                }

                if (cur !== '' && cur !== null && cur !== undefined) {
                    return acc + 1
                }

                return acc
            }, 0)
        }
    },
    methods: {
        load() {
            this.$refs.table?.load();
        }
    }
}
</script>
