import Api from "@/lib/Api";
import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        roles: [
            {
                text: i18n.t('admin'),
                value: 'admin',
                color: 'black',
                dark: true
            },
            {
                text: i18n.t('seller'),
                value: 'seller',
                color: 'red',
                dark: true
            },
            {
                text: i18n.t('assembler'),
                value: 'assembler',
                color: 'green',
                dark: true
            },
            {
                text: 'Usuário',
                value: 'customer',
                color: 'info',
                dark: true
            },
            {
                text: 'Promotor',
                value: 'promoter',
                color: 'yellow',
            },
        ]
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setData(state, data) {
            state.data = data;
        }
    },
    actions: {
        async load({ state, commit }) {
            if (state.loading) return

            commit('setLoading', true)

            const response = await Api.getUsers()

            if (response.error) {
                commit('setLoading', false)
                return
            }

            commit('setData', response.message)
            commit('setLoading', false)
        }
    },
    getters: {
        byRole: state => roles => {
            const roleList = Array.isArray(roles) ? roles : [roles]

            return state.data.filter(user => roleList.includes(user.role))
        },
        sellers: state => state.data.filter(user => user.role === 'seller'),
        assemblers: state => state.data.filter(user => user.role === 'assembler'),
        promoters: state => state.data.filter(user => user.role === 'promoter'),
        active: state => state.data.filter(user => !user.filed),
        academyOwners: state => state.data.filter(user => user.role === 'customer'),
    }
}
