<template>
    <v-card>
        <v-data-table
            :headers="headers"
            :items="calculatedVersions"
            :items-per-page="5"
            sort-by="version"
            sort-desc
        >
            <template #[`item.created`]="{ item }">
                {{ item.created ? $date.defaultFormat(item.created) : '-' }}
            </template>

            <template #[`item.total_price`]="{ item }">
                {{ $currency.main(item.total_price) }}
            </template>

            <template #[`item.final_price`]="{ item }">
                {{ $currency.main(item.final_price) }}
            </template>

            <template #[`item.final_discount_percentage`]="{ item }">
                {{ item.final_discount_percentage }}%
            </template>

            <template #[`item.nationalized_cost`]="{ item }">
                {{ $currency.main(item.nationalized_cost) }}
            </template>

            <template #[`item.fob_cost`]="{ item }">
                {{ $currency.usd(item.fob_cost) }}
            </template>

            <template #[`item.status`]="{ item }">
                <v-chip
                    small
                    dark
                    :color="$utils.getProposalStatusColor(item.status)"
                >
                    {{ $utils.getProposalStatusLabel(item.status) }}
                </v-chip>
            </template>


            <template #[`item.actions`]="props">
                <slot
                    name="actions"
                    v-bind="props"
                />
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: {
        versions: {
            type: Array,
            default: () => [],
        },
        showFob: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        calculatedVersions() {
            let items = [];

            items = this.versions.map(v => {
                const current_version = v;

                let fob_cost = 0;
                let nationalized_cost = 0;

                current_version.items.forEach(item => {
                    fob_cost += item.FOB_cost * item.quantity;
                    nationalized_cost += item.nationalized_cost * item.quantity;
                })

                return {
                    ...v,
                    fob_cost: fob_cost,
                    nationalized_cost: nationalized_cost,
                }
            });

            return items;
        },
        isAdmin() {
            return this.$store.getters['isAdmin']
        },
        statuses() {
            if (!this.isAdmin) {
                return [
                    'pending_approval',
                    'archived',
                ]
            }

            return [
                'new',
                'in_negotiation',
                'pending_approval',
                'approved',
                'archived',
            ]
        },
        headers() {
            const headers = [
                { text: this.$tc('version'), value: 'version' },
                { text: this.$t('date'), value: 'created' },
                { text: this.$t('totalPrice'), value: 'total_price' },
                { text: this.$tc('discount'), value: 'final_discount_percentage' },
                { text: this.$t('finalPrice'), value: 'final_price' },
                {
                    text: this.$t('status'),
                    value: 'status',
                    sort: (a, b) => {
                        const statuses = {
                            approved: 1,
                            pending_approval: 2,
                            in_negotiation: 3,
                            new: 1,
                            archived: 5,
                        }

                        return statuses[a] - statuses[b]
                    }
                },
            ]

            if (this.showFob) {
                headers.splice(2, 0, { text: 'FOB', value: 'fob_cost' }, { text: this.$t('nationalizedPrice'), value: 'nationalized_cost' });
            }

            if (this.$scopedSlots.actions) {
                headers.push({
                    text: this.$t('actions'),
                    value: 'actions',
                    sortable: false,
                });
            }

            return headers;
        }
    },
    methods: {}
}
</script>