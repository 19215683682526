<template>
    <v-navigation-drawer
        v-model="show"
        right
        fixed
        temporary
        width="320"
        disable-route-watcher
        hide-overlay
    >
        <v-card flat>
            <v-card-title>
                {{ $t('filters') }}

                <v-spacer />

                <v-btn
                    icon
                    @click="show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="filters.search"
                            :label="$t('search')"
                            outlined
                            hide-details
                            clearable
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-select
                            v-model="filters.proposal_id"
                            :label="$tc('proposal')"
                            :items="proposals"
                            item-text="name"
                            item-value="_id"
                            multiple
                            outlined
                            hide-details
                            clearable
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-select
                            v-model="filters.order_id"
                            :label="$tc('order')"
                            :items="orders"
                            item-text="text"
                            item-value="_id"
                            outlined
                            multiple
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="filters.assembly_status"
                            :label="$tc('assemblyStatus')"
                            :items="$store.state.customerProducts.assemblyStatus"
                            item-text="text"
                            item-value="value"
                            outlined
                            hide-details
                            clearable
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        drawer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            proposals: [],
            orders: [],
        }
    },
    computed: {
        filters: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        show: {
            get() {
                return this.drawer;
            },
            set(value) {
                this.$emit('update:drawer', value);
            },
        },
    },
    methods: {
        async setProposals() {
            const response = await this.$api.getProposals({
                customer_id: this.academy._id,
            });

            if (response.error) {
                return;
            }

            this.proposals = response.message.filter(p => p.current_version.status === 'approved')
        },
        async setOrders() {
            const response = await this.$api.getOrderProposalsByCustomer(this.academy._id);

            if (response.error) {
                return;
            }

            this.orders = response.message.map(op => ({
                _id: op.order_id,
                text: '#' + this.$number.zeroFill(op.order_number)
            }))
        },

        async load() {
            await this.setProposals();
            await this.setOrders();
        }
    }
}
</script>
