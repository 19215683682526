var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tooltip)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',{
                ...props.attrs,
                ..._vm.$attrs,
            },false),{
                ...props.on,
                ..._vm.$listeners,
            }),[_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):_c('v-btn',_vm._g(_vm._b({},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }