import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/plugins/i18n";
import { appConfig } from "@/plugins/appConfig";
import mainRoutes from "./routes";

Vue.use(VueRouter);

const routes = []

routes.push(...mainRoutes)

routes.push(
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: () => import("../views/404/404.vue"),
        meta: {
            layout: false,
            title: i18n.t("pageNotFound"),
        },
    }
)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});


router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.layout === "dashboard" || to.meta.auth;

    i18n.locale = to.meta.locale || appConfig.language

    const loggedIn = localStorage.getItem("token");

    if (!loggedIn && authRequired && !to.meta.public && to.name !== "Login") {
        next("/login");
    } else {
        next();
    }
});

export default router;
