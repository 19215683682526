<template>
    <app-dialog
        v-model="dialog"
        max-width="500"
    >
        <v-card>
            <v-card-title>
                Adicionar academia
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    @submit.prevent="submit"
                >
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.name"
                                autocomplete="nope"
                                :label="$t('name')"
                                outlined
                                hide-details="auto"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.email"
                                autocomplete="nope"
                                :label="$t('email')"
                                outlined
                                hide-details="auto"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.phone"
                                autocomplete="nope"
                                :label="$t('phone')"
                                outlined
                                hide-details="auto"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.cnpj"
                                autocomplete="nope"
                                :label="$t('taxID')"
                                outlined
                                hide-details="auto"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.gym_address"
                                autocomplete="nope"
                                :label="$t('gymAddress')"
                                outlined
                                hide-details="auto"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="payload.state"
                                label="Estado"
                                :items="$states"
                                outlined
                                hide-details="auto"
                                item-value="value"
                                item-text="text"
                                :rules="[$rules.required()]"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-switch
                                inset
                                label="É contribuinte ICMS?"
                                v-model="payload.contribuinte_icms"
                                messages="Atenção: preencha este campo corretamente. Essa informação é imprescindível para o cálculo correto da tributação."
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.inscricao_estadual"
                                autocomplete="nope"
                                label="Inscrição estadual"
                                outlined
                                hide-details="auto"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                color="primary"
                                block
                                :loading="saving"
                                type="submit"
                            >
                                {{ $t('save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </app-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        saving: false,
        payload: {}
    }),
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        async submit() {
            if (!this.$refs.form.validate()) {
                return
            }

            this.saving = true;

            const response = await this.$api.createCustomer(this.payload);

            if (response.error) {
                this.saving = false;
                return;
            }


            setTimeout(() => {
                this.dialog = false;
                this.saving = false;
                this.$router.push(`/academies/${response.message._id}`);
            }, 800)

        }
    },
    watch: {
        dialog(value) {
            // Reset payload when dialog is closed
            if (!value) {
                this.payload = {}
                this.$refs.form?.reset()
                return
            }
        },
    },
}
</script>

<style scoped>

</style>
