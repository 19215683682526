<template>
    <div>
        <v-card-text>
            <v-row dense>
                <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                >
                    <v-text-field
                        v-model="filters.search"
                        :label="$t('search')"
                        outlined
                        hide-details
                        clearable
                    />
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                >
                    <v-select
                        v-model="filters.proposal"
                        :label="$tc('proposal')"
                        :items="proposals"
                        item-text="name"
                        item-value="_id"
                        outlined
                        hide-details
                        clearable
                    />
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                >
                    <v-select
                        v-model="filters.order"
                        :label="$tc('order')"
                        :items="orders"
                        item-text="text"
                        item-value="_id"
                        outlined
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                >
                    <v-select
                        v-model="filters.assemblyStatus"
                        :label="$tc('assemblyStatus')"
                        :items="$store.state.customerProducts.assemblyStatus"
                        item-text="text"
                        item-value="value"
                        outlined
                        hide-details
                        clearable
                    />
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-btn
                        color="primary"
                        height="40"
                        :to="`/customers/${customerId}/products-qrcodes`"
                    >
                        <v-icon left>
                            mdi-qrcode
                        </v-icon>

                        Ver códigos QR
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <template v-if="selected.length">
            <v-card-title>
                {{ $t('edit') }}
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        lg="3"
                    >
                        <v-select
                            v-model="payload.assemblyStatus"
                            :label="$tc('assemblyStatus')"
                            :items="$store.state.customerProducts.assemblyStatus"
                            item-text="text"
                            item-value="value"
                            outlined
                            hide-details
                            clearable
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        lg="3"
                    >
                        <v-btn
                            @click="saveSelected"
                            color="primary"
                            height="100%"
                            :loading="savingSelected"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>


        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filteredItems"
            :show-select="editable"
            :loading="loading"
            item-key="_id"
        >
            <template #[`item.product.image`]="{ value }">
                <v-avatar
                    rounded
                    size="80"
                    v-if="value"
                >
                    <img :src="value">
                </v-avatar>
            </template>

            <template #[`item.proposal.name`]="props">
                <slot
                    name="proposal"
                    v-bind="props"
                >
                    {{ props.value || $t('proposal') }}
                </slot>
            </template>

            <template #[`item.order.number`]="props">
                <slot
                    name="order"
                    v-bind="props"
                >
                    #{{ $number.zeroFill(props.value) }}
                </slot>
            </template>

            <template #[`item.number`]="{ value }">
                {{ $number.zeroFill(value, 3) }}
            </template>

            <template #[`item.assembly.date`]="{ value }">
                {{ value ? $date.defaultFormat(value) : '-' }}
            </template>

            <template #[`item.assembly.status`]="{ value }">
                <app-chip-status
                    small
                    :value="value"
                    :items="$store.state.customerProducts.assemblyStatus"
                />
            </template>

            <template #[`item.assembly.observations`]="{ value }">
                {{ value || '-' }}
            </template>

            <template #[`item.actions`]="props">
                <v-tooltip left>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="openTicket(props.item)"
                        >
                            <v-icon
                                medium
                                color="primary"
                            >
                                mdi-face-agent
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('openTicket') }}</span>
                </v-tooltip>

                <slot
                    name="actions"
                    v-bind="props"
                />
            </template>
        </v-data-table>

        <TicketNewDialog
            v-model="dialog"
            :customer-product-id="customerProductId"
        />
    </div>
</template>

<script>
export default {
    props: {
        customerId: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false, 
            savingSelected: false,
            selected: [],
            payload: {
                assemblyStatus: null,
            },
            proposals: [],
            orders: [],
            items: [],
            filters: {
                supplier: null,
                category: null,
                subcategory: null,
            },

            customerProductId: null,
            dialog: false,
        }
    },
    computed: {
        isCustomer() {
            return this.$store.getters.isCustomer;
        },
        headers() {
            const headers = [
                {
                    value: 'product.image',
                    sortable: false,
                },
                {
                    text: this.$t('product'),
                    value: 'product.name',
                    width: 365
                },
                {
                    text: this.$t('proposal'),
                    value: 'proposal.name',
                },
                {
                    text: this.$tc('order'),
                    value: 'order.number',
                },
                {
                    text: this.$tc('number'),
                    value: 'number',
                },
                {
                    text: this.$t('assemblyDate'),
                    value: 'assembly.date',
                },
                {
                    text: this.$t('assemblyStatus'),
                    value: 'assembly.status',
                },
            ]

            if (this.$scopedSlots.actions) {
                headers.push({
                    text: this.$t('actions'),
                    value: 'actions',
                    sortable: false,
                });
            }

            return headers;
        },
        filteredItems() {
            const { search, proposal, order, assemblyStatus } = this.filters;

            return this.items.filter(item => {
                let matched = true;

                if (search) {
                    matched = matched && this.$utils.searchObject(search, item, ['product.name', 'product.code'])
                }

                if (proposal) {
                    matched = matched && item.proposal._id === proposal;
                }

                if (order) {
                    matched = matched && item.order._id === order;
                }

                if (assemblyStatus) {
                    matched = matched && item.assembly.status === assemblyStatus;
                }

                return matched;
            });
        }
    },
    methods: {
        async setProposals() {
            const response = await this.$api.getProposals({
                customer_id: this.customerId
            });

            if (response.error) {
                return;
            }

            this.proposals = response.message.filter(p => p.current_version.status === 'approved')
        },
        async setOrders() {
            const response = await this.$api.getOrderProposalsByCustomer(this.customerId);

            if (response.error) {
                return;
            }

            this.orders = response.message.map(op => ({
                _id: op.order_id,
                text: '#' + this.$number.zeroFill(op.order_number)
            }))
        },
        async setProducts() {
            const response = await this.$api.getCustomerProducts({
                customer_id: this.customerId
            });

            if (response.error) {
                return;
            }

            this.items = response.message
        },
        async load() {
            this.loading = true;

            await Promise.allSettled([
                this.setProposals(),
                this.setOrders(),
                this.setProducts()
            ]);

            setTimeout(() => {
                this.loading = false;
            }, 800);
        },
        goToProposal(item) {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.customerId}/dashboard/proposals/${item.proposal._id}`)
            }

            this.$router.push(`/proposals/${item.proposal._id}`)
        },
        goToOrder(item) {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.customerId}/dashboard/orders/${item.order._id}`)
            }

            this.$router.push(`/orders/${item.order._id}`)
        },
        async saveSelected() {
            this.savingSelected = true;

            for await (const item of this.selected) {
                await this.$api.updateCustomerProduct({
                    _id: item._id,
                    assembly: {
                        status: this.payload.assemblyStatus,
                        observations: item.assembly.observations,
                        images: item.assembly.images || []
                    }
                });
            }

            this.$toast('success', this.$t('entityUpdated', [this.$t('products')]))

            this.savingSelected = false;

            this.load();
        },
        openTicket(item) {
            this.customerProductId = item._id;
            this.dialog = true;
        },
    },
    created() {
        this.load();
    }
}
</script>
