<template>
    <e-data-table-server
        ref="table"
        :headers="headers"
        :filters="filters"
        sort-by="created"
        sort-desc
        request="getAcademies"
    >
        <template #[`item.tax_register_valid`]="{ item }">
            <v-icon
                small
                v-if="!item.tax_register_valid"
                color="error"
            >
                mdi-close-circle
            </v-icon>
            <v-icon
                small
                v-else
                color="success"
            >
                mdi-check-circle
            </v-icon>
        </template>

        <template #[`item.created`]="{ item }">
            {{ $date.defaultFormat(item.created) }}
        </template>

        <template #[`item.actions`]="{ item }">
            <e-btn
                icon
                :to="`/academies/${item._id}?title=${item.name || '' }`"
                tooltip="Editar"
            >
                <v-icon
                    medium
                    color="primary"
                >
                    mdi-file-edit-outline
                </v-icon>
            </e-btn>

            <e-btn
                icon
                tooltip="Deletar"
                :loading="deletingId === item._id"
                @click="deleteItem(item)"
            >
                <v-icon
                    medium
                    color="error"
                >
                    mdi-delete
                </v-icon>
            </e-btn>
        </template>
    </e-data-table-server>
</template>

<script>
export default {
    props: {
        filters: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            deletingId: null,
            headers: [
                { text: '#', value: "friendly_code", width: 60 },
                { text: 'Fiscal', value: "tax_register_valid", width: 90 },
                { text: 'Nome', value: "name" },
                { text: 'Email', value: "email" },
                { text: 'CPNJ', value: "cnpj" },
                { text: 'Telefone', value: "phone" },
                { text: 'Criado em', value: "created", width: 160 },
                { text: "", value: "actions", sortable: false, width: 120 },
            ]
        }
    },
    methods: {
        load() {
            this.$refs.table?.load()
        },
        async deleteItem(item) {
            const value = await this.$store.dispatch('confirmDialog')

            if (!value) {
                return
            }

            this.deletingId = item._id

            const response = await this.$api.deleteAcademy({
                id: item._id
            })

            if (response.error) {
                this.deletingId = null
                return
            }

            setTimeout(async () => {
                this.load()

                this.deletingId = null
            }, 500)
        }
    }
}
</script>
