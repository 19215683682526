<template>
    <v-navigation-drawer
        v-model="show"
        right
        fixed
        temporary
        width="320"
        disable-route-watcher
        hide-overlay
    >
        <v-card flat>
            <v-card-title>
                {{ $t('filters') }}

                <v-spacer />

                <v-btn
                    icon
                    @click="show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-switch
                            v-model="filters.has_approved_proposals"
                            label="Com propostas aprovadas"
                            outlined
                            hide-details="auto"
                            clearable
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.owner_id"
                            label="Dono da Academia"
                            outlined
                            hide-details="auto"
                            :items="$store.getters['users/academyOwners']"
                            multiple
                            item-text="name"
                            item-value="_id"
                            clearable
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        drawer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        filters: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },	
        show: {
            get() {
                return this.drawer;
            },
            set(value) {
                this.$emit('update:drawer', value);
            },
        },
    },
}
</script>
