<template>
    <v-app>
        <app-confirm-dialog />

        <component
            v-if="layout"
            :is="layout" 
        />
        
        <router-view v-else />
    </v-app>
</template>

<script>
export default {
    components: {
        // layouts
        Dashboard: () => import("@/layouts/Dashboard.vue"),
        Public: () => import("@/layouts/Public.vue"),

        // components
        AppConfirmDialog: () => import("@/components/AppConfirmDialog.vue"),
    },
    data() {
        return {
            loading: false,
            layouts: [
                {
                    name: 'dashboard',
                    component: () => import('@/layouts/Dashboard.vue'),
                },
                {
                    name: 'public',
                    component: () => import('@/layouts/Public.vue'),
                },
                {
                    name: 'print',
                    component: () => import('@/layouts/Public.vue'),
                },
                {
                    name: 'promoter',
                    component: () => import('@/layouts/Promoter.vue'),
                }
            ]
        }
    },
    computed: {
        appConfig() {
            return this.$appConfig;
        },
        currentUser() {
            return this.$store.state.currentUser;
        },
        layout(){
            const item = this.layouts.find(item => item.name === this.$route.meta.layout);

            return item ? item.component : null;

        }
    },
    methods: {
        async load(){
            // load store data
            const promises = [
                this.$store.dispatch('products/load'),
                this.$store.dispatch('subcategories/load'),
                this.$store.dispatch('categories/load'),
            ];

            await Promise.all(promises);
        },
    },
    mounted(){
        this.$store.dispatch('loadCurrentUser')

        this.load()
    },
    created() {
        window.document.title = this.$appConfig.title;
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.$store.dispatch('users/load');
                }

            }
        },
    },
};
</script>
