import { i18n } from "@/plugins/i18n";

export default [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login/Login.vue"),
    },
    {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard/Dashboard.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/my-profile",
        name: "MyProfile",
        component: () => import("../views/MyProfile/MyProfile.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("myProfile"),
        },
    },
    {
        path: "/users",
        name: "UserList",
        component: () => import("../views/UserList/UserList.vue"),
        meta: {
            layout: "dashboard",
            title: 'Usuários',
        },
    },
    {
        path: "/users/:userId",
        name: "UserSingle",
        component: () => import("../views/UserSingle/UserSingle.vue"),
        meta: {
            layout: "dashboard",
            title: 'Usuário',
        },
    },
    {
        path: "/products",
        name: "ProductList",
        component: () => import("../views/ProductList/ProductList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product", 2),
        },
    },
    {
        path: "/products/new",
        name: "ProductNew",
        component: () => import("../views/ProductSingle/ProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("newEntity", [i18n.tc("product", 1)]),
        },
    },
    {
        path: "/products/:productId",
        name: "ProductSingle",
        component: () => import("../views/ProductSingle/ProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product", 1),
        },
    },
    {
        path: "/minimum-markups",
        name: "MinimumMarkupList",
        component: () => import("../views/MinimumMarkupList/MinimumMarkupList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("markup", 2),
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/Settings/Settings.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("setting", 2),
        },
    },
    {
        path: "/proposals",
        name: "ProposalList",
        component: () => import("../views/ProposalList/ProposalList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("proposal", 2),
        },
    },
    {
        path: "/proposals/new",
        name: "ProposalNew",
        component: () => import("../views/ProposalNew/ProposalNew.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/shipping",
        name: "Shipping",
        component: () => import("../views/Shipping/Shipping.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/proposals/:proposalId",
        name: "ProposalSingle",
        props: true,
        component: () => import("../views/ProposalSingle/ProposalSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions/new",
        name: "ProposalVersionNew",
        component: () => import("../views/ProposalVersionSingle/ProposalVersionSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions/:versionId/edit",
        name: "ProposalVersion",
        component: () => import("../views/ProposalVersionSingle/ProposalVersionSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions",
        name: "PublicVersionList",
        component: () => import("../views/PublicVersionList/PublicVersionList.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/proposals/:proposalId/versions/:versionId",
        name: "PublicVersionSingle",
        component: () => import("../views/PublicVersionSingle/PublicVersionSingle.vue"),
        meta: {
            layout: "public",
            title: i18n.tc("proposal", 1),
        },
    },
    {
        path: "/customers",
        name: "CustomerList",
        component: () => import("../views/CustomerList/CustomerList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("client", 2),
        },
    },
    {
        path: "/customers/:customerId",
        name: "CustomerSingle",
        component: () => import("../views/CustomerSingle/CustomerSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("client"),
        },
    },
    {
        path: "/customers/:customerId/products/:customerProductId",
        name: "CustomerProductSingle",
        component: () => import("../views/CustomerProductSingle/CustomerProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product"),
        },
    },
    {
        path: "/suppliers",
        name: "SupplierList",
        component: () => import("../views/SupplierList/SupplierList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("supplier", 2),
        },
    },
    {
        path: "/orders",
        name: "OrderList",
        component: () => import("../views/OrderList/OrderList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("order", 2),
        },
    },
    {
        path: "/orders/new",
        name: "OrderNew",
        component: () => import("../views/OrderNew/OrderNew.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("newEntity", [i18n.tc("order", 1)]),
        },
    },
    {
        path: "/orders/:orderId",
        name: "OrderSingle",
        component: () => import("../views/OrderSingle/OrderSingle.vue"),
        meta: {
            layout: "dashboard",
            auth: true
        },
    },
    {
        path: "/orders/:orderId/suppliers/:supplierId/document",
        name: "OrderSupplierDocument",
        component: () => import("../views/OrderSupplierDocument/OrderSupplierDocument.vue"),
        meta: {
            layout: "public",
            locale: "en-US",
        },
    },
    {
        path: "/orders/:orderId/suppliers/:supplierId/labels",
        name: "OrderSupplierLabelList",
        component: () => import("../views/OrderSupplierLabelList/OrderSupplierLabelList.vue"),
        meta: {
            layout: false,
            public: true,
            locale: "en-US",
        },
    },
    {
        path: "/categories",
        name: "CategoryList",
        component: () => import("../views/CategoryList/CategoryList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("category", 2),
        },
    },
    {
        path: "/categories/:categoryId",
        name: "CategorySingle",
        component: () => import("../views/CategorySingle/CategorySingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("category"),
        },
    },
    {
        path: "/subcategories",
        name: "SubcategoryList",
        component: () => import("../views/SubcategoryList/SubcategoryList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("subcategory", 2),
        },
    },
    {
        path: '/proposals-per-customer',
        name: 'ProposalsPerCustomer',
        component: () => import('../views/ProposalsPerCustomer/ProposalsPerCustomer.vue'),
        meta: {
            layout: "dashboard",
            title: i18n.t('proposalsPerClient'),
        }
    },
    {
        path: "/customers/:customerId/proposals",
        name: "CustomerProposalList",
        component: () => import("../views/CustomerProposalList/CustomerProposalList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("proposal", 2),
        },
    },
    {
        path: "/product-groups",
        name: "ProductGroupList",
        component: () => import("../views/ProductGroupList/ProductGroupList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("groups"),
        },
    },
    {
        path: "/product-groups/:productGroupId",
        name: "ProductGroupSingle",
        component: () => import("../views/ProductGroupSingle/ProductGroupSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("group"),
        },
    },
    {
        path: '/order-settings',
        component: () => import('../views/OrderSettings/OrderSettings.vue'),
        meta: {
            layout: 'dashboard',
        }
    },
    {
        path: '/orders/:orderId/proposals/:proposalId/shipping',
        component: () => import('../views/OrderShipping/OrderShipping.vue'),
        meta: {
            layout: 'public',
            public: true,
        }
    },
    {
        path: '/tickets',
        name: 'TicketList',	
        component: () => import('@/views/TicketList/TicketList.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('tickets'),
        }
    },
    {
        path: '/commission-settings',
        name: 'CommissionSettings',
        component: () => import('@/views/CommissionSettingList/CommissionSettingList.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('settings'),
        }
    },
    {
        path: '/commission-settings/new',
        name: 'CommissionSettingNew',
        component: () => import('@/views/CommissionSettingSingle/CommissionSettingSingle.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('newEntity', [i18n.t('commissionSetting')]),
        }
    },
    {
        path: '/commission-settings/:commissionSettingId',
        name: 'CommissionSettingSingle',
        component: () => import('@/views/CommissionSettingSingle/CommissionSettingSingle.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('commissionSetting'),
        }
    },
    {
        path: '/commission-reports',
        name: 'CommissionReportList',
        component: () => import('@/views/CommissionReportList/CommissionReportList.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('commissionReport'),
        }
    },
    {
        path: '/commission-reports/new',
        name: 'CommissionReportNew',
        component: () => import('@/views/CommissionReportSingle/CommissionReportSingle.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('newEntity', [i18n.t('commissionReport')]), 
        }
    },
    {
        path: '/commission-reports/:commissionReportId',
        name: 'CommissionReportSingle',
        component: () => import('@/views/CommissionReportSingle/CommissionReportSingle.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('commissionReport'), 
        }
    },
    {
        path: '/seller-commissions',
        name: 'SellerCommissionList',
        component: () => import('@/views/UserCommissionList/UserCommissionList.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('commissionReport'), 
        }
    },
    {
        path: '/seller-commission-dashboard',
        name: 'SellerCommissionDashboard',
        component: () => import('@/views/SellerCommissionDashboard/SellerCommissionDashboard.vue'),
        meta: {
            layout: 'dashboard',
            title: i18n.t('commissions'), 
        }
    },
    {
        path: '/education/categories',
        name: 'EducationCategories',
        component: () => import('@/views/Education/EditCategory.vue'),
        meta: {
            layout: 'dashboard',
            title: "Central Educativa - Categorias", 
        }
    },
    {
        path: '/education',
        name: 'Education',
        component: () => import('@/views/Education/EducationContentList.vue'),
        meta: {
            layout: 'dashboard',
            title: "Central Educativa", 
        }
    },
    {
        path: '/education/edit',
        name: 'EducationEdit',
        component: () => import('@/views/Education/EditContent.vue'),
        meta: {
            layout: 'dashboard',
            title: "Central Educativa - Edição de Conteúdo", 
        }
    },

    {
        path: '/academies',
        name: 'AcademyList',
        component: () => import('@/views/AcademyList/AcademyList.vue'),
        meta: {
            layout: 'dashboard',
            title: "Academias", 
        }
    },
    {
        path: '/academies/:academyId',
        name: 'AcademySingle',
        component: () => import('@/views/AcademySingle/AcademySingle.vue'),
        meta: {
            layout: 'dashboard',
            title: "Academias", 
        }
    },
    {
        name: 'product.public',
        path: '/products/:productId/public',
        component: () => import('@/views/ProductSinglePublic/ProductSinglePublic.vue'),
        meta: {
            public: true,
            layout: 'public',
            layoutOptions: {
                hideLinks: true,
            }
        }
    },
    {
        name: 'promoter.dashboard.proposals',
        path: '/promoters/:userId/dashboard/proposals',
        component: () => import('@/views/PromoterProposalList/PromoterProposalList.vue'),
        meta: {
            layout: 'promoter',
        }
    },
    {
        name: 'promoter.dashboard.proposal-single',
        path: '/promoters/:userId/dashboard/proposals/:proposalId',
        component: () => import('@/views/PromoterProposalSingle/PromoterProposalSingle.vue'),
        meta: {
            layout: 'promoter', 
        }
    },
    {
        name: 'promoter.dashboard.commission-list',
        path: '/promoters/:userId/dashboard/commisions',
        component: () => import('@/views/UserCommissionList/UserCommissionList.vue'),
        meta: {
            layout: 'promoter',
        }
    },
    {
        name: 'customer.customer-product-qr-code-list',
        path: '/customers/:customerId/products-qrcodes',
        component: () => import('@/views/CustomerProductQrCodeList/CustomerProductQrCodeList.vue'),
        meta: {
            layout: 'emtpy',
        }
    }
];
