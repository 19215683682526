
<template>
    <div>
        <e-data-table-server
            ref="table"
            v-model="selected"
            :headers="headers"
            :show-select="editable"
            :filters="filters"
            :request="request"
            item-key="_id"
        >
            <template #[`item.product.image`]="props">
                <v-avatar
                    rounded
                    size="80"
                    v-if="props.value"
                >
                    <img :src="props.value">
                </v-avatar>
            </template>

            <template #[`item.proposal.name`]="props">
                <slot
                    name="proposal"
                    v-bind="props"
                >
                    {{ props.value || $t('proposal') }}
                </slot>
            </template>

            <template #[`item.order.number`]="props">
                <slot
                    name="order"
                    v-bind="props"
                >
                    #{{ $number.zeroFill(props.value) }}
                </slot>
            </template>

            <template #[`item.number`]="props">
                {{ $number.zeroFill(props.value, 3) }}
            </template>

            <template #[`item.assembly.date`]="props">
                {{ props.value ? $date.defaultFormat(props.value) : '-' }}
            </template>

            <template #[`item.assembly.status`]="props">
                <app-chip-status
                    small
                    :value="props.value"
                    :items="$store.state.customerProducts.assemblyStatus"
                />
            </template>

            <template #[`item.assembly.observations`]="props">
                {{ props.value || '-' }}
            </template>

            <template #[`item.actions`]="props">
                <v-tooltip left>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="openTicket(props.item)"
                        >
                            <v-icon
                                medium
                                color="primary"
                            >
                                mdi-face-agent
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('openTicket') }}</span>
                </v-tooltip>

                <v-tooltip left>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :to="`/customers/${academy._id}/products/${props.item._id}`"
                        >
                            <v-icon
                                medium
                                color="primary"
                            >
                                mdi-eye
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('edit') }}</span>
                </v-tooltip>
            </template>
        </e-data-table-server>

        <TicketNewDialog
            v-model="dialog"
            :customer-product-id="customerProductId"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false, 
            savingSelected: false,
            selected: [],
            payload: {
                assemblyStatus: null,
            },
            proposals: [],
            orders: [],
            items: [],
            customerProductId: null,
            dialog: false,
        }
    },
    computed: {
        academy: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        headers() {
            const headers = [
                {
                    value: 'product.image',
                    sortable: false,
                },
                {
                    text: this.$t('product'),
                    value: 'product.name',
                    width: 365
                },
                {
                    text: this.$t('proposal'),
                    value: 'proposal.name',
                },
                {
                    text: this.$tc('order'),
                    value: 'order.number',
                },
                {
                    text: this.$tc('number'),
                    value: 'number',
                },
                {
                    text: this.$t('assemblyDate'),
                    value: 'assembly.date',
                },
                {
                    text: this.$t('assemblyStatus'),
                    value: 'assembly.status',
                },
                {
                    text: 'Ações',
                    value: 'actions',
                }
            ]

            return headers;
        },
    },
    methods: {
        load() {
            this.$refs.table?.load();
        },
        async request(options) {
            const response = await this.$api.getAcademyProducts({
                ...options,
                academy_id: this.academy._id,
            });

            if (response.error) {
                return {
                    data: [],
                    meta: {},
                }
            }

            return {
                data: response.message,
                meta: response.meta,
            }
        },
        goToProposal(item) {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.academy._id}/dashboard/proposals/${item.proposal._id}`)
            }

            this.$router.push(`/proposals/${item.proposal._id}`)
        },
        goToOrder(item) {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.academy._id}/dashboard/orders/${item.order._id}`)
            }

            this.$router.push(`/orders/${item.order._id}`)
        },
        async saveSelected() {
            this.savingSelected = true;

            for await (const item of this.selected) {
                await this.$api.updateCustomerProduct({
                    _id: item._id,
                    assembly: {
                        status: this.payload.assemblyStatus,
                        observations: item.assembly.observations,
                        images: item.assembly.images || []
                    }
                });
            }

            this.$toast('success', this.$t('entityUpdated', [this.$t('products')]))

            this.savingSelected = false;

            this.load();
        },
        openTicket(item) {
            this.customerProductId = item._id;
            this.dialog = true;
        },
    },
}
</script>
