export default {
    namespaced: true,
    state: {
        roles: [
            {
                text: 'Proprietário',
                value: 'owner',
                color: 'purple', 
                dark: true,
            },
            {
                text: 'Colaborador',
                value: 'user',
                color: 'blue', 
                dark: true,
            },
        ],
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        },
    },
    actions: {
    }
}
