<template>
    <v-dialog
        v-model="dialog"
        max-width="500"
    >
        <v-card>
            <v-form
                ref="form"
                @submit.prevent="submit"
            >
                <v-card-title>
                    {{ $t('createEntity', [$t('ticket').toLowerCase()]) }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="payload.title"
                                :label="$t('title')"
                                :rules="[$rules.required()]"
                                outlined
                                hide-details="auto"
                            />
                        </v-col>
                        
                        <v-col cols="12">
                            <v-textarea
                                v-model="payload.description"
                                :label="$t('description')"
                                :rules="[$rules.required()]"
                                outlined
                                hide-details="auto"
                            />
                        </v-col>
                        
                        <v-col cols="12">
                            <v-row>
                                <v-col
                                    v-for="(image, index) in payload.images"
                                    :key="index"
                                    cols="4"
                                >
                                    <v-card outlined>
                                        <app-img
                                            :src="image"
                                            width="100%"
                                            height="80"
                                            contain
                                        />

                                        <v-card-actions>
                                            <v-btn
                                                color="error"
                                                small
                                                block
                                                @click="payload.images.splice(index, 1)"
                                            >
                                                {{ $t('remove') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                                <v-col cols="12">
                                    <v-card
                                        outlined
                                        @click="addImage"
                                    >
                                        <v-card-text class="text-center">
                                            {{ $t('addEntity', [$t('images')]) }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn
                        color="primary"
                        text
                        :disabled="saving"
                        @click="dialog = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        type="submit"
                        :loading="saving"
                    >
                        {{ $t('create') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        customerProductId: {
            type: String,
            default: ''
        },
        redirectToList: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            saving: false,
            payload: {
                _id: null,
                title: '',
                description: '',
                images: [],
            }
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        reset() {
            this.payload = {
                _id: null,
                title: '',
                description: '',
                images: [],
            }

            this.$refs.form.resetValidation();
        },
        async submit() {

            if (!this.$refs.form.validate()) return;

            this.saving = true;

            const images = [];

            for await (const i of this.payload.images) {
                const upload = await this.$api.uploadImage(i.file);

                if (upload.error) {
                    this.saving = false;
                    return
                }

                images.push({
                    src: upload.message,
                });

            }

            const response = await this.$api.createTicket({
                ...this.payload,
                images,
                customer_product_id: this.customerProductId
            });

            if (response.error) {
                this.saving = false;
                return
            }

            
            setTimeout(() => {
                this.dialog = false;
                this.saving = false;
                
                this.$emit('created', response.message);

                if (this.redirectToList) {
                    this.goToTicketList();
                }
            }, 800);
        },
        async addImage(){
            const files = await this.$utils.pickFile({
                accept: 'image/*',
                multiple: true,
            })

            if (!files) return

            files.forEach(file => {
                this.payload.images.push({
                    src: URL.createObjectURL(file),
                    file,
                })
            })

        },
        goToTicketList() {
            if (this.$store.getters.isCustomer) {
                return this.$router.push({
                    name: 'customer.dashboard.ticket-list',
                })
            }

            return this.$router.push({
                name: 'TicketList'
            })
        }
    },
    watch: {
        dialog: {
            imediate: true,
            handler(v) {
                if (!v) return this.reset()
            }
        }
    }
}
</script>
