<template>
    <v-card>
        <v-card-title>
            {{ $tc('client') }}
            
            <v-spacer />

            <v-btn
                color="primary"
                :disabled="proposal.customer?._id === payload"
                :loading="saving"
                @click="submit"
            >
                {{ $t('save') }}
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="payload"
                        :label="$t('name')"
                        :items="items"
                        outlined
                        hide-details
                        item-text="name"
                        item-value="_id"
                        :filter="filterCustomers"
                    >
                        <template #item="{item}">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name" />
                                <v-list-item-subtitle v-text="item.email" />
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        dialog: false,
        saving: false,
        payload: null,
        items: []
    }),
    computed: {
        proposal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        filterCustomers(item, queryText){
            if (item.name?.toLowerCase().includes(queryText.toLowerCase())) {
                return true
            }
            
            if (item.email?.toLowerCase().includes(queryText.toLowerCase())) {
                return true
            }

            return false
        },
        async load(){
            this.payload = this.proposal.customer?._id

            const response = await this.$api.getCustomers()
    
            if (response.error) return
    
            this.items = response.message
        },
        async submit(){
            this.saving = true

            const response = await this.$api.updateProposal({
                _id: this.proposal._id,
                customer: this.payload
            })

            if (response.error) return

            this.proposal.customer = this.payload

            this.saving = false

            this.$toast('success', this.$t('entityUpdated', [this.$t('proposal')]))
        },
    },
    mounted() {
        this.load()
    }
}
</script>
