<template>
    <v-card>
        <v-card-title>
            <div>
                {{ proposal.name }}
            </div>

            <template v-if="!readonly">
                <v-spacer />

                <v-btn
                    color="primary"
                    class="mr-2"
                    @click="copyDialog = true"
                >
                    {{ $t('copyProposal') }}
                </v-btn>

                <v-btn
                    color="primary"
                    :loading="saving"
                    @click="submit"
                >
                    {{ $t('save') }}
                </v-btn>
            </template>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="proposal.name"
                        :label="$t('name')"
                        :readonly="readonly"
                        outlined
                        hide-details
                    >
                        <template #selection="{ item }">
                            <v-chip
                                :color="$utils.getProposalStatusColor(item || 'new')"
                                dark
                            >
                                {{ $utils.getProposalStatusLabel(item || 'new') }}
                            </v-chip>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    v-if="showSeller"
                >
                    <v-autocomplete
                        v-model="proposal.user"
                        :label="$t('seller')"
                        :items="$store.getters['users/byRole'](['admin', 'seller'])"
                        item-text="name"
                        item-value="_id"
                        outlined
                        hide-details
                        :readonly="readonly"
                    >
                        <template #item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col
                    v-if="showPromoter"
                    cols="12"
                >
                    <v-autocomplete
                        v-model="proposal.promoter"
                        label="Promotor"
                        :items="$store.getters['users/promoters']"
                        item-text="name"
                        item-value="_id"
                        outlined
                        hide-details
                        :readonly="readonly"
                    >
                        <template #item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12">
                    <v-select
                        :value="proposal.last_version_status"
                        :label="$t('lastVersionStatus')"
                        outlined
                        hide-details
                        readonly
                        :items="$store.state.versions.versionStatus"
                        item-text="text"
                        item-value="value"
                    >
                        <template #selection="{ item }">
                            <app-chip-status
                                :value="item.value"
                                :items="$store.state.versions.versionStatus"
                            />
                        </template>
                    </v-select>
                </v-col>

                <v-col
                    cols="12"
                    v-if="proposal.created"
                >
                    <v-text-field
                        :value="$date.defaultFormat(proposal.created)"
                        :label="$t('createdAt')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col
                    cols="12"
                    v-if="proposal.changed"
                >
                    <v-text-field
                        :value="$date.defaultFormat(proposal.changed)"
                        :label="$t('updatedAt')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
                <v-col
                    cols="12"
                    v-if="proposal.last_version_date"
                >
                    <v-text-field
                        :value="$date.defaultFormat(proposal.last_version_date)"
                        :label="$t('lastVersionDate')"
                        outlined
                        hide-details
                        readonly
                    />
                </v-col>
            </v-row>
        </v-card-text>

        <app-dialog
            v-model="copyDialog"
            max-width="500"
        >
            <v-form @submit.prevent="createProposalCopy">
                <v-card>
                    <v-card-title>
                        {{ $t('copyProposal') }}
                    </v-card-title>
    
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="copyPayload.name"
                                    :label="$t('name')"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                            
                            <v-col cols="12">
                                <v-select
                                    v-model="copyPayload.version"
                                    :label="$t('initialVersion')"
                                    :items="proposal.versions"
                                    outlined
                                    hide-details
                                    item-text="version"
                                    item-value="version"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
    
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            :disabled="coping"
                            @click="copyDialog = false"
                        >
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="coping"
                        >
                            {{ $t('copy') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </app-dialog>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false
        },
        showPromoter: {
            type: Boolean,
            default: false
        },
        showSeller: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        saving: false,
        coping: false,
        copyDialog: false,
        copyPayload: {
            name: '',
        }
    }),
    computed: {
        proposal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    created(){
        if (this.showPromoter || this.showSeller) {
            this.$store.dispatch('users/load')
        }
    },
    methods: {
        async submit() {
            this.saving = true;

            const response = await this.$api.updateProposal({
                _id: this.proposal._id,
                name: this.proposal.name,
            })

            if (response.error) {
                this.saving = false
                return
            }

            setTimeout(() => {
                this.$emit('saved')

                this.$toast('success', this.$t('entityUpdated', [this.$t('proposal')]))
    
                this.saving = false
                
            }, 800)

        },
        async createProposalCopy() {
            this.coping = true;

            const response = await this.$api.createProposalCopy({
                proposal_id: this.proposal._id,
                name: this.copyPayload.name,
                version_id: this.copyPayload.version,
            })

            if (response.error) {
                this.coping = false
                return
            }

            this.$toast('success', this.$t('entityCreated', [this.$t('proposal')]))

            setTimeout(() => {    
                this.coping = false
                this.copyDialog = false
                
                this.$router.push(`/proposals/${response.message._id}`)
            }, 800)

        },
    },
    watch: {
        copyDialog(value) {
            if (value) {
                this.copyPayload = {
                    name: `${this.proposal.name} (${this.$t('copy')})`,
                    version: 1
                }
            }
        }
    }
}
</script>
