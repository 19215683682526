<template>
    <v-row v-if="customerProduct">
        <v-col cols="12">
            <v-card>
                <v-card-title>
                    {{ customerProduct.product.name }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            md="4"
                        >
                            <v-card outlined>
                                <v-img
                                    :src="customerProduct.product.image"
                                    width="100%"
                                    height="auto"
                                />
                            </v-card>
                        </v-col>
                        <v-col
                            cols="12"
                            md="8"
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('name')"
                                        :value="customerProduct.product.name"
                                        readonly
                                        outlined
                                        hide-details
                                    />
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('assemblyDate')"
                                        :value="$date.defaultFormat(customerProduct.assembly.date)"
                                        readonly
                                        outlined
                                        hide-details
                                    />
                                </v-col>


                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('proposal')"
                                        :value="customerProduct.proposal.name"
                                        readonly
                                        outlined
                                        hide-details
                                        append-icon="mdi-arrow-right"
                                        @click:append="goToProposal"
                                    />
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        :label="$tc('order')"
                                        :value="'#' + $number.zeroFill(customerProduct.order.number)"
                                        readonly
                                        outlined
                                        hide-details
                                        append-icon="mdi-arrow-right"
                                        @click:append="goToProposal"
                                    />
                                </v-col>

                                <v-col
                                    cols="12"
                                    v-if="customerProduct.product.default_weight_stack"
                                >
                                    <v-text-field
                                        :value="customerProduct.product.default_weight_stack"
                                        :label="$t('defaultWeightStack')"
                                        type="number"
                                        outlined
                                        readonly
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col
                                    cols="12"
                                    v-if="customerProduct.product.weight_stack_description"
                                >
                                    <v-text-field
                                        :value="customerProduct.product.weight_stack_description"
                                        :label="$t('weightStackDescription')"
                                        readonly
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col
                                    cols="12"
                                >
                                    <v-btn
                                        v-if="customerProduct.product.manual_url"
                                        color="primary"
                                        class="mr-2"
                                        large
                                        @click="() => $navigateTo(customerProduct.product.manual_url, { target: '_blank' })"
                                    >
                                        {{ $t('viewManual') }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="mr-2"
                                        large
                                        @click="ticketDialog = true"
                                    >
                                        <v-icon left>
                                            mdi-face-agent
                                        </v-icon>
                                        {{ $t('openTicket') }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="mr-2"
                                        large
                                        :to="{
                                            name: 'product.public',
                                            params: {
                                                productId: customerProduct.product._id
                                            },
                                            query: {
                                                customerProductId: customerProduct._id,
                                                customerId: customerId
                                            }
                                        }"
                                    >
                                        <v-icon left>
                                            mdi-eye
                                        </v-icon>
                                        Pagina publica
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <TicketNewDialog
                    v-model="ticketDialog"
                    :customer-product-id="customerProductId"
                />
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-card-title>
                    <div class="grow">
                        {{ $t('assembly') }}
                    </div>

                    <v-btn
                        v-if="!isCustomer"
                        :disabled="saving"
                        color="primary"
                        class="mr-2"
                        outlined
                        @click="load"
                    >
                        {{ $t('reset') }}
                    </v-btn>

                    <v-btn
                        v-if="!isCustomer"
                        :loading="saving"
                        class="mr-2"
                        color="primary"
                        @click="saveAndCloseTab"
                    >
                        {{ $t('saveAndCloseTab') }}
                    </v-btn>

                    <v-btn
                        v-if="!isCustomer"
                        :loading="saving"
                        color="primary"
                        @click="save"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="customerProduct.assembly.status"
                                :label="$t('assemblyStatus')"
                                :items="$store.state.customerProducts.assemblyStatus"
                                :readonly="isCustomer"
                                item-text="text"
                                item-value="value"
                                outlined
                                hide-details
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            v-if="customerProduct.assembly.checklist"
                        >
                            <v-card outlined>
                                <v-card-subtitle>
                                    {{ $t('assemblyChecklist') }}
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-row class="my-2 mb-6">
                                        <v-col
                                            cols="12"
                                            class="py-1"
                                            v-for="(item, index) in customerProduct.assembly.checklist"
                                            :key="`cl_item_${index}`"
                                        >
                                            <span style="font-weight: 600;">- {{ item.name }}</span>
                                            <div class="d-flex align-center my-2">
                                                <v-radio-group
                                                    v-model="item.status"
                                                    row
                                                    hide-details="auto"
                                                    class="my-0"
                                                >
                                                    <v-radio
                                                        color="success"
                                                        label="OK"
                                                        value="ok"
                                                    />
                                                    <v-radio
                                                        color="red"
                                                        label="NOK"
                                                        value="nok"
                                                    />
                                                </v-radio-group>
                                                <v-text-field
                                                    dense
                                                    :label="$t('assemblyObservations')"
                                                    v-model="item.obs"
                                                    outlined
                                                    hide-details
                                                />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                v-model="customerProduct.assembly.observations"
                                :label="$t('assemblyObservations')"
                                :readonly="isCustomer"
                                outlined
                                hide-details
                                rows="5"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-subtitle class="d-flex">
                                    <div class="grow">
                                        {{ $t('photos') }}
                                    </div>

                                    <v-btn
                                        v-if="!isCustomer"
                                        color="primary"
                                        :disabled="saving"
                                        @click="addFiles"
                                    >
                                        {{ $t('add') }}
                                    </v-btn>
                                </v-card-subtitle>

                                <v-card-text>
                                    <v-row align="stretch">
                                        <v-col
                                            v-for="(i, index) in customerProduct.assembly.images"
                                            :key="i.src"
                                            cols="12"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                        >
                                            <v-card outlined>
                                                <app-img
                                                    :src="i.src"
                                                    width="100%"
                                                    :height="imageHeight"
                                                />

                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-text-field
                                                                v-model="i.date"
                                                                :label="$t('date')"
                                                                v-mask="'##/##/####'"
                                                                :readonly="isCustomer"
                                                                outlined
                                                                hide-details
                                                            />
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-text-field
                                                                v-model="i.description"
                                                                :label="$t('description')"
                                                                :readonly="isCustomer"
                                                                outlined
                                                                hide-details
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                                <v-card-actions
                                                    v-if="!isCustomer"
                                                    class="justify-end"
                                                >
                                                    <v-btn
                                                        color="error"
                                                        block
                                                        @click="removeImage(index)"
                                                    >
                                                        {{ $t('remove') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        customerId: {
            type: String,
            required: true,
        },
        customerProductId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            customerProduct: null,
            saving: false,
            ticketDialog: false,
        }
    },
    computed: {
        pageLoading: {
            get() {
                return this.$store.state.pageLoading;
            },
            set(value) {
                this.$store.commit('setPageLoading', value);
            },
        },
        imageHeight() {
            if (this.$vuetify.breakpoint.xlOnly) {
                return 400
            }

            if (this.$vuetify.breakpoint.smAndUp) {
                return 300
            }


            return 200
        },
        isCustomer() {
            return this.$store.getters.isCustomer
        }
    },
    methods: {
        async load() {
            this.pageLoading = true

            const response = await this.$api.getCustomerProductById({
                id: this.customerProductId
            });

            this.customerProduct = response.message

            if (!this.customerProduct.assembly) this.customerProduct.assembly = {};

            if (this.customerProduct.subcategory?.checklist) {
                if (!this.customerProduct.assembly.checklist || !this.customerProduct.assembly.checklist.length) {
                    this.customerProduct.assembly.checklist = this.customerProduct.subcategory.checklist;
                }
            }

            setTimeout(() => {
                this.pageLoading = false
            }, 500)
        },
        async save() {
            this.saving = true

            const images = []

            for await (const i of this.customerProduct.assembly.images) {
                if (!i.file) {
                    images.push(i)
                    continue
                }

                const upload = await this.$api.uploadImage(i.file)

                if (upload.error) {
                    this.saving = false
                    return
                }

                images.push({
                    src: upload.message,
                    description: i.description,
                    date: i.date
                })
            }


            const assembly = {
                ...this.customerProduct.assembly,
                images
            }


            const response = await this.$api.updateCustomerProduct({
                _id: this.customerProduct._id,
                assembly
            });

            if (response.error) {
                this.saving = false
                return
            }

            setTimeout(() => {
                this.saving = false
                this.$toast('success', this.$t('entityUpdated', [this.$t('product')]))
            }, 500)

        },
        removeImage(index) {
            this.customerProduct.assembly.images.splice(index, 1)
        },
        goToProposal() {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.customerId}/dashboard/proposals/${this.customerProduct.proposal._id}`)
            }

            this.$router.push(`/proposals/${this.customerProduct.proposal._id}`)
        },
        goToOrder() {
            if (this.isCustomer) {
                return this.$router.push(`/customers/${this.customerId}/dashboard/orders/${this.customerProduct.order._id}`)
            }

            this.$router.push(`/orders/${this.customerProduct.order._id}`)
        },
        async addFiles() {
            const files = await this.$utils.pickFile({
                accept: 'image/*',
                multiple: true
            });

            for (const file of files) {
                this.customerProduct.assembly.images.push({
                    src: URL.createObjectURL(file),
                    file: file,
                    description: '',
                    date: this.$date.format(new Date(), 'dd/mm/yyyy'),
                })
            }
        },
        async saveAndCloseTab() {
            await this.save()

            setTimeout(() => {
                window.close()
            }, 800)

        },
    },
    created() {
        this.load()
    },
}
</script>
