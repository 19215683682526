<template>
    <div>
        <v-card>
            <v-card-text class="d-flex align-center">
                <v-text-field
                    :value="filters.search"
                    :label="$t('proposalSearchPlaceholder')"
                    append-icon="mdi-magnify"
                    dense
                    clearable
                    single-line
                    outlined
                    hide-details
                    class="!max-w-sm-80 mr-4"
                    @input="doSearch"
                />

                <v-btn 
                    color="primary"
                    v-if="$listeners.refresh"
                    class="mr-4"
                    @click="$emit('refresh')"
                >
                    <v-icon>mdi-refresh</v-icon> 
                </v-btn>
    
                <v-badge
                    color="red"
                    :content="filtersLength"
                    :value="filtersLength"
                    overlap
                >
                    <v-btn
                        color="primary"
                        @click="drawer = !drawer"
                    >
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </v-badge>
    
    
                <v-btn
                    v-if="!hideAddBtn"
                    color="primary"
                    to="/proposals/new"
                    class="ml-auto"
                >
                    {{ $t('addNew') }}
                </v-btn>
            </v-card-text>
        </v-card>

        <v-navigation-drawer
            v-model="drawer"
            right
            fixed
            temporary
            width="320"
            disable-route-watcher
            hide-overlay
        >
            <v-card flat>
                <v-card-title>
                    {{ $t('filters') }}

                    <v-spacer />

                    <v-btn
                        icon
                        @click="drawer = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="filters.status"
                                :label="$t('status')"
                                outlined
                                hide-details="auto"
                                :items="statuses"
                                multiple
                                item-text="text"
                                item-value="value"
                                clearable
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            v-if="showSeller"
                        >
                            <v-autocomplete
                                v-model="filters.userId"
                                :label="$t('seller')"
                                outlined
                                hide-details="auto"
                                :items="sellers"
                                multiple
                                item-text="name"
                                item-value="_id"
                                clearable
                            />
                        </v-col>

                        <v-col cols="12">
                            <app-date-picker
                                v-model="filters.startDate"
                                :label="$t('startDate')"
                                outlined
                                clearable
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12">
                            <app-date-picker
                                v-model="filters.endDate"
                                :label="$t('endDate')"
                                outlined
                                clearable
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>

import debounce from 'lodash/debounce';

export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        showSeller: {
            type: Boolean,
            default: false,
        },
        hideAddBtn: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        drawer: false,
        sellers: [],
    }),
    computed: {
        statuses() {
            return this.$store.state.versions.versionStatus 
        },
        filters: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },	
        filtersLength() {
            const { status, userId, startDate, endDate } = this.filters

            const isFilled = [
                status?.length,
                userId?.length,
                startDate,
                endDate
            ]

            return isFilled.filter(Boolean).length
        },
    },
    methods: {
        async setSellers() {
            if (!this.showSeller) {
                return
            }

            const response = await this.$api.getUsers({
                role: ['admin', 'seller'],
                orderBy: 'name',
            });

            if (response.error) {
                return
            }

            this.sellers = response.message;
        },
        doSearch: debounce(function(value) {            
            this.$set(this.filters, 'search', value)
        }, 500),
    },
    watch: {
        showSeller: {
            immediate: true,
            handler(value) {
                if (value && !this.sellers.length) {
                    this.setSellers()
                }
            }
        },
    },
}
</script>
